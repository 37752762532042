
import { defineComponent } from "vue";
import DxSelectBox from "devextreme-vue/select-box";
import DateControl from "@/components/controls/base/DateControl.vue";
import { DateTime } from "luxon";
import ArrayStore from "devextreme/data/array_store";
import DxButton from "devextreme-vue/button";
import api from "@/services/api";
import { PlanCleanupMode } from "./enums";
import { confirm } from "devextreme/ui/dialog";
import CleanupInfoDialog from "@/components/info/CleanupInfoDialog.vue";
import { DxLoadPanel } from "devextreme-vue/load-panel";

const modes = [
  { id: PlanCleanupMode.OlderThanMonth, caption: "Старше месяца" },
  { id: PlanCleanupMode.OlderThanYear, caption: "Старше года" },
  { id: PlanCleanupMode.OlderThanDate, caption: "Старше даты..." },
];

export default defineComponent({
  components: {
    DxSelectBox,
    DateControl,
    DxButton,
    CleanupInfoDialog,
    DxLoadPanel,
  },
  props: {},
  computed: {
    modes() {
      return modes;
    },
  },
  methods: {
    onShowInfo() {
      this.$refs.infoDialog.open();
    },
    onModeChange(e: any) {
      this.selectedMode = e.value;
      this.isShowDate = e.value == PlanCleanupMode.OlderThanDate;
      if (this.isShowDate) {
        this.date = DateTime.now();
      } else {
        this.date = null;
      }
    },
    async onClean() {
      let result = confirm(
        "Вы уверены, что хотите очистить данные планирования?",
        "Подтверждение действия"
      );
      const res = await result;
      if (!res) return;

      try {
        this.cleaning = true;
        await api.post(`Plan/Cleanup`, {
          mode: this.selectedMode,
          date: this.date,
        });
      } catch {
        this.cleaning = false;
      } finally {
        this.cleaning = false;
        this.showEndMessage();
      }
    },
    showEndMessage() {
      this.showMessage = true;
      setTimeout(this.unshowEndMessage, 2000);
    },
    unshowEndMessage() {
      this.showMessage = false;
    },
  },
  data() {
    return {
      showMessage: false,
      loadingPanelPosition: { of: "#Cleanup-info" },
      isShowDate: false,
      selectedMode: undefined,
      cleaning: false,
      value: modes[0],
      date: null,
      modesStore: new ArrayStore({
        data: modes,
        key: "id",
      }),
    };
  },
});
